<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
    />
    <land-intro-icons-ver
      :back-color="about.backColor"
      :assist-color="about.assistColor"
      :header="about.header"
      :features="about.features"
      :button="about.button"
    />
    <land-intro-image-text
      :space="intro1.space"
      :back-color="intro1.backColor"
      :assist-color="intro1.assistColor"
      :header="intro1.header"
      :content="intro1.content"
    />
    <land-intro-image-text
      :space="intro2.space"
      :back-color="intro2.backColor"
      :assist-color="intro2.assistColor"
      :header="intro2.header"
      :content="intro2.content"
    />
    <land-contact-way
      dark
      back-color="blue-grey darken-4"
    />
  </section>
</template>

<script>
  // import app from '@/api/co.app'
  import mixContactBanner from '@/pages/mixins/contact/mix.contact.banner'

  export default {
    metaInfo: { title: '联系我们' },
    mixins: [
      mixContactBanner
    ],
    data () {
      return {
        about: {
          backColor: 'white',
          assistColor: 'blue',
          header: {
            title: '南京江北新区智能制造产业园',
            subtitle: '',
          },
          features: [{
            icon: 'mdi-atom-variant',
            iconColor: 'blue',
            title: '定位',
            subtitle: '聚焦智能制造产业生态群'
          }, {
            icon: 'mdi-diamond-stone',
            iconColor: 'blue',
            title: '愿景',
            subtitle: '筑梦活力江北，赢在智造之城'
          }, {
            icon: 'mdi-seal-variant',
            iconColor: 'blue',
            title: '使命',
            subtitle: '“三区一平台”国家使命'
          }, {
            icon: 'mdi-broadcast',
            iconColor: 'blue',
            title: '口号',
            subtitle: '创新策源地、引领区、重要增长极'
          }],
          button: undefined
        },
        intro1: {
          backColor: 'grey lighten-4',
          assistColor: 'primary',
          space: 50,
          header: {
            title: '',
            subtitle: '',
          },
          content: {
            title: '招商政策',
            text: '工业企业重大设备技改项目奖补政策、工业企业升规稳规奖补政策、高层次创新创业人才支持政策、企业挂牌上市奖励政策、总部落户奖励政策、人才安居政策',
            showIndicator: false,
            imageWidth: 560,
            imageHeight: 316,
            images: [
              { src: 'https://resources.landcoo.com/znzz-220729/220729-ppt-18.png', },
              { src: 'https://resources.landcoo.com/znzz-220729/220729-ppt-19.png' },
              { src: 'https://resources.landcoo.com/znzz-220729/220729-ppt-20.png' }
            ],
            hovers: [{
              color: 'blue darken-1',
              // name: 'mdi-monitor-cellphone-star',
              image: 'https://oss.landcoo.com/png/1778062264734191616.png',
              text: '制造产业'
            }, {
              color: 'cyan darken-2',
              // name: 'mdi-medication',
              image: 'https://oss.landcoo.com/png/1778062921595752448.png',
              text: '医疗产业'
            }, {
              color: 'deep-purple darken-1',
              // name: 'mdi-rhombus-split',
              image: 'https://oss.landcoo.com/png/1778063022556844032.png',
              text: '数字产业'
            }],
            button: undefined,
            contact: {
              text: '电话：025-5805 1234'
            }
          },
        },
        intro2: {
          backColor: 'white',
          assistColor: 'primary',
          space: 50,
          header: {
            title: '',
            subtitle: '',
          },
          content: {
            title: '空间规划',
            text: '园区正全面升级空间规划，搭建从苗圃孵化、载体入驻、规模开发等多种模式相匹配的各类空间要素，满足各发展阶段企业的入驻需求”',
            reversed: true,
            showIndicator: false,
            imageWidth: 560,
            imageHeight: 316,
            images: [
              { src: 'https://resources.landcoo.com/znzz-220729/220729-ppt-27.png', },
              { src: 'https://resources.landcoo.com/znzz-220729/220729-ppt-28.png' },
              { src: 'https://resources.landcoo.com/znzz-220729/220729-ppt-29.png' }
            ],
            hovers: [{
              color: 'deep-purple darken-1',
              // name: 'mdi-monitor-cellphone-star',
              image: 'https://oss.landcoo.com/png/1778070231269380096.png',
              text: '智合医工'
            }, {
              color: 'indigo darken-2',
              // name: 'mdi-medication',
              image: 'https://oss.landcoo.com/png/1778065039174012928.png',
              text: '气象谷'
            }, {
              color: 'green darken-2',
              // name: 'mdi-rhombus-split',
              image: 'https://oss.landcoo.com/png/1778065120199577600.png',
              text: '智城大厦'
            }],
            button: undefined,
            contact: {
              text: '电话：025-5805 1236'
            }
          },
        },
      }
    },
    created () {
      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()
    }
  }
</script>
